import { reset, state } from '@/utils/common/state';

export function installState(Vue) {
  if (Vue) {
    Vue.prototype.$state = {
      _vm: new Vue(
        {
          data: {
            externalId: null,
            applicationNumber: null,
            activeTask: null,
            stepData: null,
            productConfiguration: null,
          },
        },
      ),
      data: state,
      getExternalId() {
        return this._vm.$data.externalId;
      },
      setExternalId(payload) {
        this._vm.$data.externalId = payload;
      },
      getApplicationNumber() {
        return this._vm.$data.applicationNumber;
      },
      setApplicationNumber(payload) {
        this._vm.$data.applicationNumber = payload;
      },
      getActiveTask() {
        return this._vm.$data.activeTask;
      },
      setActiveTask(payload) {
        this._vm.$data.activeTask = payload;
      },
      setStepData(payload) {
        this._vm.$data.stepData = payload;
      },
      getStepData() {
        return this._vm.$data.stepData;
      },
      reset() {
        Object.assign(this._vm.$data, {
          externalId: null,
          applicationNumber: null,
          activeTask: null,
          stepData: null,
          productConfiguration: null,
        });

        reset();
      },
    };
  } else {
    console.error('Vue not found');
  }
}
