export enum OptionType {
    MARITAL_STATUS = 'MARITAL_STATUS',
    EDUCATION = 'EDUCATION',
    RESIDENCE_TYPE = 'RESIDENCE_TYPE',
    INCOME_TYPE = 'INCOME_TYPE',
    MONTHLY_PAYMENT_DAY = 'MONTHLY_PAYMENT_DAY',
    ID_DOCUMENT_TYPE = 'ID_DOCUMENT_TYPE',
    YES_NO = 'YES_NO',
    CONTRACT_FILE = 'CONTRACT_FILE',
    DEPENDENTS = 'DEPENDENTS',
    FIELD_OF_ACTIVITY = 'FIELD_OF_ACTIVITY',
    SIGNING_METHOD = 'SIGNING_METHOD',
    IDENTIFICATION_METHOD = 'IDENTIFICATION_METHOD',
    LANGUAGE_CODE = 'LANGUAGE_CODE',
    ASSET_CONDITION = 'ASSET_CONDITION',
    INTEREST_TYPE = 'INTEREST_TYPE'
}

export enum SearchType {
    PARTNER_TYPE_CODE = 'partnerTypeCode',
    USER_STATUS = 'userStatus',
    BONUS_APPLICATION_STATUS = 'bonusApplicationStatus',
    CHANNEL_TYPE = 'channelType',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    USERNAME = 'username',
    ROLE_CODE = 'roleCode',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    CONTRACT_SIGNING_OFFICE = 'contractSigningOffice',
    CONSENT_SIGNING_OFFICE = 'consentSigningOffice',
    CONSENT_SIGNING_USER = 'consentSigningUser',
    // toolbar search
    NAME = 'name',
    PERSONAL_CODE = 'personalIdentityCode',
    APPLICATION_NUMBER = 'applicationNumber',
    CONTRACT_NUMBER = 'contractNumber',
    // data object containing search
    PARTNER = 'partner',
    CHANNEL = 'channel',
    USER = 'user',
}

export enum Decision {
    APPROVED = 'Approved',
    APPROVED_ADDITIONAL_CHECK = 'ApprovedAdditionalCheck',
    APPROVED_BRING_COBORROWER = 'ApprovedBringCoBorrower',
    APPROVED_BRING_SPOUSE = 'ApprovedBringSpouse',
    ADDITIONAL_DATA_NEEDED = 'AdditionalDataNeeded',
    REJECTED = 'Rejected',
    REJECTED_REFINANCE = 'RejectedRefinance',
    REJECTED_BRING_COBORROWER = 'RejectedBringCoBorrower',
    REJECTED_BRING_SPOUSE = 'RejectedBringSpouse',
    UNDECIDED = 'Undecided',
    UNMAPPED = 'UNMAPPED',
}

export enum CollateralSubType {
    VEHICLES = 'VEHICLES',
    LEASED_VEHICLES = 'LEASED_VEHICLES',
    UNMAPPED = 'UNMAPPED',
}

export enum IdDocumentType {
    PASSPORT = 'PASSPORT',
    ID_CARD = 'ID_CARD',
    FOREIGN_PASSPORT = 'FOREIGN_PASSPORT',
    FOREIGN_ID_CARD = 'FOREIGN_ID_CARD',
    TEMPORARY_RESIDENCE_PERMIT_CARD = 'TEMPORARY_RESIDENCE_PERMIT_CARD',
    PERMANENT_RESIDENCE_PERMIT_CARD = 'PERMANENT_RESIDENCE_PERMIT_CARD',
    PERMANENT_RESIDENCE_PERMIT_DOCUMENT = 'PERMANENT_RESIDENCE_PERMIT_DOCUMENT',
    DIPLOMATIC_PASSPORT = 'DIPLOMATIC_PASSPORT',
    DRIVERS_LICENSE = 'DRIVERS_LICENSE',
    EU_RESIDENCE_PERMIT_CARD = 'EU_RESIDENCE_PERMIT_CARD',
    ALIENS_PASSPORT = 'ALIENS_PASSPORT',
    OTHER = 'OTHER',
    UNMAPPED = 'UNMAPPED',
}

export enum ContractSigningChannel {
    OFFICE = 'OFFICE',
    POST = 'POST',
    ONLINE_SERVICES = 'ONLINE_SERVICES',
    PARTNER = 'PARTNER',
    UNMAPPED = 'UNMAPPED',
}

export enum ContractSigningMethod {
    ID_CARD = 'ID_CARD',
    MOBILE_SIGNATURE = 'MOBILE_SIGNATURE',
    SMART_ID = 'SMART_ID',
    MANUALLY_SIGNED = 'MANUALLY_SIGNED',
    PAYPOST_SIGNATURE = 'PAYPOST_SIGNATURE',
    OTHER_ELECTRONIC_FORMAT = 'OTHER_ELECTRONIC_FORMAT',
    EVROTRUST = 'EVROTRUST',
    BANKLINK_SWEDBANK = 'BANKLINK_SWEDBANK',
    BANKLINK_SEB = 'BANKLINK_SEB',
    BANKLINK_LUMINOR = 'BANKLINK_LUMINOR',
    BANK_ID = 'BANK_ID',
    UNMAPPED = 'UNMAPPED'
}

export enum ContractFileType {
    ONE_FILE = 'ONE_FILE',
    TWO_FILES = 'TWO_FILES'
}

export enum GoodsCategory {
    BEAUTY = 'BEAUTY',
    CONSTRUCTION = 'CONSTRUCTION',
    CONSUMER_ELECTRONICS = 'CONSUMER_ELECTRONICS',
    EQUIPMENT_AND_MACHINERY = 'EQUIPMENT_AND_MACHINERY',
    FINANCIAL = 'FINANCIAL',
    FURNISHINGS = 'FURNISHINGS',
    GARDEN = 'GARDEN',
    GREEN = 'GREEN',
    HOBBY = 'HOBBY',
    HOME_ELECTRONICS = 'HOME_ELECTRONICS',
    MOTO = 'MOTO',
    MOTOHOME_ACCESSORIES = 'MOTOHOME_ACCESSORIES',
    SERVICES = 'SERVICES',
    UNMAPPED = 'UNMAPPED',
    UNREGISTERED_VEHICLE = 'UNREGISTERED_VEHICLE',
    VEHICLE_ACCESSORIES = 'VEHICLE_ACCESSORIES',
    WATERCRAFT = 'WATERCRAFT',
}

export enum LoanPurpose {
    PURCHASING_RENTAL_PROPERTY = 'PURCHASING_RENTAL_PROPERTY',
    BUILDING_RENTAL_PROPERTY = 'BUILDING_RENTAL_PROPERTY',
    BUILDING_OTHER_REAL_ESTATE = 'BUILDING_OTHER_REAL_ESTATE',
    PURCHASING_NEW_MOTOR_VEHICLES = 'PURCHASING_NEW_MOTOR_VEHICLES',
    PURCHASING_USED_MOTOR_VEHICLES = 'PURCHASING_USED_MOTOR_VEHICLES',
    PURCHASING_ELECTRIC_VEHICLES = 'PURCHASING_ELECTRIC_VEHICLES',
    PURCHASING_SOLAR_PANELS = 'PURCHASING_SOLAR_PANELS',
    PURCHASING_SOLAR_COLLECTORS = 'PURCHASING_SOLAR_COLLECTORS',
    PURCHASING_GROUND_HEAT_PUMP = 'PURCHASING_GROUND_HEAT_PUMP',
    PURCHASING_ELECTRIC_CARS = 'PURCHASING_ELECTRIC_CARS',
    PURCHASING_ELECTRIC_SCOOTERS = 'PURCHASING_ELECTRIC_SCOOTERS',
    PURCHASING_ELECTRIC_BICYCLES = 'PURCHASING_ELECTRIC_BICYCLES',
    PURCHASING_AND_RENOVATING_HOME = 'PURCHASING_AND_RENOVATING_HOME',
    OTHER_RENEWABLE_PROJECTS = 'OTHER_RENEWABLE_PROJECTS',
    PURCHASING_HOME = 'PURCHASING_HOME',
    BUILDING_HOME = 'BUILDING_HOME',
    RENOVATING_HOME = 'RENOVATING_HOME',
    HOME_EXCHANGE = 'HOME_EXCHANGE',
    HOBBY = 'HOBBY',
    LEISURE = 'LEISURE',
    COMBINING_REGULAR_LOANS = 'COMBINING_REGULAR_LOANS',
    COMBINING_FAST_LOANS = 'COMBINING_FAST_LOANS',
    OTHER = 'OTHER',
    PURCHASING_RESIDENTIAL_LAND = 'PURCHASING_RESIDENTIAL_LAND',
    DAILY_SETTLEMENTS = 'DAILY_SETTLEMENTS',
    PURCHASING_HOME_APPLIANCES_ELECTRONICS = 'PURCHASING_HOME_APPLIANCES_ELECTRONICS',
    PURCHASING_FURNISHINGS = 'PURCHASING_FURNISHINGS',
    PURCHASING_OR_BUILDING_HOME = 'PURCHASING_OR_BUILDING_HOME',
    ACQUIRING_EDUCATION = 'ACQUIRING_EDUCATION',
    TRAVELING = 'TRAVELING',
    FOR_OTHER_PERSON = 'FOR_OTHER_PERSON',
    MEDICAL_SERVICES = 'MEDICAL_SERVICES',
    WEDDING = 'WEDDING',
    PURCHASING_OTHER_REAL_ESTATE = 'PURCHASING_OTHER_REAL_ESTATE',
    PURCHASING_OTHER_FIXED_ASSETS = 'PURCHASING_OTHER_FIXED_ASSETS',
    PURCHASING_MOTOR_VEHICLES = 'PURCHASING_MOTOR_VEHICLES',
    ACQUIRED_TRANSPORT_VEHICLES = 'ACQUIRED_TRANSPORT_VEHICLES',
    REPAIRING_MOTOR_VEHICLES = 'REPAIRING_MOTOR_VEHICLES',
    STARTING_COMMERCIAL_ACTIVITY = 'STARTING_COMMERCIAL_ACTIVITY',
    EXPANDING_COMMERCIAL_ACTIVITY = 'EXPANDING_COMMERCIAL_ACTIVITY',
    ACQUIRING_SECURITIES = 'ACQUIRING_SECURITIES',
    COMBINING_LOANS = 'COMBINING_LOANS',
    UNMAPPED = 'UNMAPPED',
}

export enum UserStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive'
}

export enum QuestionnaireType {
    FEEDBACK_FORM = 'FEEDBACK_FORM',
    AML_FORM_LV = 'AML_FORM_LV'
}

export enum YesNo {
    YES = 'YES',
    NO = 'NO'
}

export enum CalculatorSummaryListUnit {
    CURRENCY = 'CURRENCY',
    MONTHS = 'MONTHS',
    PERCENT = 'PERCENT',
}

export enum PricingType {
    PERCENT = 'PERCENT',
    NUMBER = 'NUMBER'
}

export enum StatusReason {
    INCOMPLETE_FOR_DECIDING = 'INCOMPLETE_FOR_DECIDING',
    DECIDING = 'DECIDING',
    NOT_CLOSED = 'NOT_CLOSED',
    OTHER = 'OTHER',
    REFINANCING = 'REFINANCING',
    SPOUSE_FLOW = 'SPOUSE_FLOW',
    ACTIVE_CONTRACT = 'ACTIVE_CONTRACT',
    REJECTED_AUTO = 'REJECTED_AUTO',
    SUSPECTED_FRAUD = 'SUSPECTED_FRAUD',
    EMPLOYMENT_RELATED_ISSUES = 'EMPLOYMENT_RELATED_ISSUES',
    ENTREPRENEUR_RELATED_ISSUES = 'ENTREPRENEUR_RELATED_ISSUES',
    SANCTIONED_PERSON = 'SANCTIONED_PERSON',
    PEP = 'PEP',
    AML = 'AML',
    SANCTIONED_AND_PE_PERSON = 'SANCTIONED_AND_PE_PERSON',
    HIGH_RISK = 'HIGH_RISK',
    OTHER_ISSUES = 'OTHER_ISSUES',
    GAMBLING = 'GAMBLING',
    MANY_FAST_LOANS = 'MANY_FAST_LOANS',
    COOPERATION_HISTORY = 'COOPERATION_HISTORY',
    MANY_LIABILITIES = 'MANY_LIABILITIES',
    LANGUAGE_BARRIER = 'LANGUAGE_BARRIER',
    COLLATERAL_RELATED_ISSUES = 'COLLATERAL_RELATED_ISSUES',
    SMALL_DOWNPAYMENT = 'SMALL_DOWNPAYMENT',
    COBORROWER_NOT_ACCEPTED = 'COBORROWER_NOT_ACCEPTED',
    ACTIVE_DEBTS = 'ACTIVE_DEBTS',
    INSUFFICIENT_REPAYMENT_CAPACITY = 'INSUFFICIENT_REPAYMENT_CAPACITY',
    INSUFFICIENT_EQUITY = 'INSUFFICIENT_EQUITY',
    INSUFFICIENT_COLLATERAL = 'INSUFFICIENT_COLLATERAL',
    APPLICATION_EXPIRED = 'APPLICATION_EXPIRED',
    OFFER_EXPIRED = 'OFFER_EXPIRED',
    RATE_TOO_HIGH = 'RATE_TOO_HIGH',
    MONTHLY_PAYMENT_TOO_HIGH = 'MONTHLY_PAYMENT_TOO_HIGH',
    COMMISSION_TOO_HIGH = 'COMMISSION_TOO_HIGH',
    OFFERED_SUM_TOO_SMALL = 'OFFERED_SUM_TOO_SMALL',
    LOAN_PERIOD_TOO_LONG = 'LOAN_PERIOD_TOO_LONG',
    WAITED_TOO_LONG_FOR_OFFER = 'WAITED_TOO_LONG_FOR_OFFER',
    CANNOT_PROVIDE_REQUIRED_DOCUMENTS = 'CANNOT_PROVIDE_REQUIRED_DOCUMENTS',
    CANNOT_SIGN_CONTRACT = 'CANNOT_SIGN_CONTRACT',
    BETTER_OFFER_ELSEWHERE = 'BETTER_OFFER_ELSEWHERE',
    NOT_INTERESTED_IN_REFINANCING = 'NOT_INTERESTED_IN_REFINANCING',
    RECEIVED_LOAN_FROM_OTHER_SOURCE = 'RECEIVED_LOAN_FROM_OTHER_SOURCE',
    FINANCIAL_SITUATION_IMPROVED = 'FINANCIAL_SITUATION_IMPROVED',
    NO_NEED = 'NO_NEED',
    CANNOT_PROVIDE_COBORROWER = 'CANNOT_PROVIDE_COBORROWER',
    APPLICANT_IS_UNREACHABLE = 'APPLICANT_IS_UNREACHABLE',
    NO_ANSWER = 'NO_ANSWER',
    PARTNER_LOAN_TAKEN = 'PARTNER_LOAN_TAKEN',
    REFUSED_DEBT_REPAYMENT = 'REFUSED_DEBT_REPAYMENT',
    NO_CONSENT = 'NO_CONSENT',
    DOUBLE_APPLICATION = 'DOUBLE_APPLICATION',
    CANNOT_FINISH_FLOW = 'CANNOT_FINISH_FLOW',
    MIN_REQUIREMENTS_NOT_MET = 'MIN_REQUIREMENTS_NOT_MET',
    LOAN_NOT_NEEDED_COVID19 = 'LOAN_NOT_NEEDED_COVID19',
    BUY_PROPERTY = 'BUY_PROPERTY',
    PRE_AUTH_REQUIREMENTS_NOT_MET = 'PRE_AUTH_REQUIREMENTS_NOT_MET',
    INSUFFICIENT_INFORMATION_PROVIDED = 'INSUFFICIENT_INFORMATION_PROVIDED',
    HOME_SOLD = 'HOME_SOLD',
    LTV_BETTER_ELSEWHERE = 'LTV_BETTER_ELSEWHERE',
    HOMEBANK_PREFERRED = 'HOMEBANK_PREFERRED',
    GUARANTEE_FEE_SMALLER_ELSEWHERE = 'GUARANTEE_FEE_SMALLER_ELSEWHERE',
    FUTURE_INTEREST = 'FUTURE_INTEREST',
    INDICATIVE_OFFER = 'INDICATIVE_OFFER',
    REQUIREMENTS_EASIER_ELSEWHERE = 'REQUIREMENTS_EASIER_ELSEWHERE',
    PROCESS_COMPLICATED = 'PROCESS_COMPLICATED',
    DOWNPAYMENT_HIGH = 'DOWNPAYMENT_HIGH',
    NEGATIVE_EXTERNAL_CREDIT_HISTORY = 'NEGATIVE_EXTERNAL_CREDIT_HISTORY',
    NEGATIVE_INTERNAL_CREDIT_HISTORY = 'NEGATIVE_INTERNAL_CREDIT_HISTORY',
    HIGH_BUSINESS_RISK = 'HIGH_BUSINESS_RISK',
    NEGATIVE_INFORMATION_OF_RELATED_PARTIES = 'NEGATIVE_INFORMATION_OF_RELATED_PARTIES',
    NEGATIVE_CHARACTER_OF_CUSTOMER = 'NEGATIVE_CHARACTER_OF_CUSTOMER',
    NEGATIVE_CREDIT_HISTORY = 'NEGATIVE_CREDIT_HISTORY',
    NEGATIVE_INFORMATION_FOUND = 'NEGATIVE_INFORMATION_FOUND',
    ECONOMIC_ACTIVITY = 'ECONOMIC_ACTIVITY',
    CUSTOMER_TYPE = 'CUSTOMER_TYPE',
    UNMAPPED = 'UNMAPPED'
}

export enum LtRequiredDocumentResolution {
    VALID = 'VALID',
    INSUFFICIENT = 'INSUFFICIENT',
    FRAUDULENT = 'FRAUDULENT',
    NOT_REQUIRED = 'NOT_REQUIRED',
    MISSING = 'MISSING',
    CANNOT_PROVIDE = 'CANNOT_PROVIDE',
    UNMAPPED = 'UNMAPPED',
}

export enum LtRefinanceType {
    LIVING_PURPOSE_MORTGAGE_LIVING_PURPOSE = 'LIVING_PURPOSE_MORTGAGE_LIVING_PURPOSE',
    OTHER_MORTGAGE = 'OTHER_MORTGAGE',
    CONSUMER_LOAN = 'CONSUMER_LOAN',
    CREDIT_CARD_OR_REVOLVING_CREDIT = 'CREDIT_CARD_OR_REVOLVING_CREDIT',
    FINANCIAL_OR_OPERATIONAL_LEASING = 'FINANCIAL_OR_OPERATIONAL_LEASING',
    INSURANCE = 'INSURANCE',
    WARRANTY = 'WARRANTY',
    FACTORING = 'FACTORING',
    GUARANTY = 'GUARANTY',
    LONG_TERM_LOAN = 'LONG_TERM_LOAN',
    SHORT_TERM_LOAN = 'SHORT_TERM_LOAN',
    CREDIT_LINE = 'CREDIT_LINE',
    OVERDRAFT = 'OVERDRAFT',
    CREDIT_LIMIT_AT_BUSINESS_CREDIT_CARD = 'CREDIT_LIMIT_AT_BUSINESS_CREDIT_CARD',
    REPURCHASE_AGREEMENT = 'REPURCHASE_AGREEMENT',
    BILL_OF_EXCHANGE = 'BILL_OF_EXCHANGE',
    ASSETS_SOLD_TO_CREDIT = 'ASSETS_SOLD_TO_CREDIT',
    OTHERS = 'OTHERS',
}

export enum LtRefinanceDocumentType {
    LIABILITIES_PROOF = 'LIABILITIES_PROOF',
    LIABILITIES_PROOF_EMAIL = 'LIABILITIES_PROOF_EMAIL',
    LIABILITIES_PROOF_SCREENSHOT = 'LIABILITIES_PROOF_SCREENSHOT',
}

export enum DocumentType {
    BANK_STATEMENT = 'BANK_STATEMENT',
    SIGNED_CONTRACT = 'SIGNED_CONTRACT',
    UNSIGNED_CONTRACT = 'UNSIGNED_CONTRACT',
    PASSPORT = 'PASSPORT',
    ID_CARD = 'ID_CARD',
    PERMANENT_RESIDENCE_PERMIT_CARD = 'PERMANENT_RESIDENCE_PERMIT_CARD',
    TEMPORARY_RESIDENCE_PERMIT_CARD = 'TEMPORARY_RESIDENCE_PERMIT_CARD',
    FOREIGN_PASSPORT = 'FOREIGN_PASSPORT',
    LIABILITIES_PROOF = 'LIABILITIES_PROOF',
    OFFER_FROM_OTHER_CREDIT_PROVIDER = 'OFFER_FROM_OTHER_CREDIT_PROVIDER',
    REGISTRY_RESPONSE_NDR = 'REGISTRY_RESPONSE_NDR',
    REGISTRY_RESPONSE = 'REGISTRY_RESPONSE',
    LOAN_APPLICATION = 'LOAN_APPLICATION',
    POPULATION_REGISTRY_RESPONSE = 'POPULATION_REGISTRY_RESPONSE',
    SALE_OFFER = 'SALE_OFFER',
    REAL_ESTATE_VALUATION_ACT = 'REAL_ESTATE_VALUATION_ACT',
    SALE_ADVERTISEMENT = 'SALE_ADVERTISEMENT',
    LAND_REGISTER = 'LAND_REGISTER',
    BUSINESS_CERTIFICATE = 'BUSINESS_CERTIFICATE',
    CASH_RECEIPT = 'CASH_RECEIPT',
    CIVIL_SERVANT_CERTIFICATE = 'CIVIL_SERVANT_CERTIFICATE',
    COMPANYS_YEARLY_PROFIT_TAX_DECLARATION = 'COMPANYS_YEARLY_PROFIT_TAX_DECLARATION',
    DEFECT_RECTIFICATION_REPORT = 'DEFECT_RECTIFICATION_REPORT',
    DISABILITY_PENSION_PROOF = 'DISABILITY_PENSION_PROOF',
    EMPLOYER_CONTINUITY_STATEMENT = 'EMPLOYER_CONTINUITY_STATEMENT',
    EMPLOYER_STATEMENT = 'EMPLOYER_STATEMENT',
    EMPLOYMENT_CONTRACT = 'EMPLOYMENT_CONTRACT',
    FARMERS_CERTIFICATE = 'FARMERS_CERTIFICATE',
    ID_PHOTO_FRONT = 'ID_PHOTO_FRONT',
    ID_PHOTO_REAR = 'ID_PHOTO_REAR',
    ID_PHOTO_PORTRAIT = 'ID_PHOTO_PORTRAIT',
    ID_PHOTO_SELF = 'ID_PHOTO_SELF',
    SIGNATURE_PHOTO = 'SIGNATURE_PHOTO',
    INCOME_EXPENSE_JOURNAL = 'INCOME_EXPENSE_JOURNAL',
    INCOME_EXPENSE_TAX_SUMMARY = 'INCOME_EXPENSE_TAX_SUMMARY',
    LIABILITIES_PROOF_EMAIL = 'LIABILITIES_PROOF_EMAIL',
    LIABILITIES_PROOF_SCREENSHOT = 'LIABILITIES_PROOF_SCREENSHOT',
    NO_LIABILITIES_PROOF = 'NO_LIABILITIES_PROOF',
    SECCI = 'SECCI',
    TAX_PROOF = 'TAX_PROOF',
    CONFIRMATION = 'CONFIRMATION',
    MANUALLY_SIGNED_CONTRACT = 'MANUALLY_SIGNED_CONTRACT',
    OTHER = 'OTHER',
    RENT_AGREEMENT = 'RENT_AGREEMENT',
    RETIREMENT_PENSION_PROOF = 'RETIREMENT_PENSION_PROOF',
    SELF_EMPLOYMENT_CERTIFICATE = 'SELF_EMPLOYMENT_CERTIFICATE',
    SIGNED_CONSENT = 'SIGNED_CONSENT',
    UNSIGNED_CONSENT = 'UNSIGNED_CONSENT',
    TRANSFER_ACT = 'TRANSFER_ACT',
    VALUATION = 'VALUATION',
    VEHICLE_IDENTITY_DATA_CERTIFICATE = 'VEHICLE_IDENTITY_DATA_CERTIFICATE',
    VEHICLE_REGISTRATION_CERTIFICATE = 'VEHICLE_REGISTRATION_CERTIFICATE',
    VEHICLE_PURCHASE_DOCUMENT = 'VEHICLE_PURCHASE_DOCUMENT',
    YEARLY_INCOME_DECLARATION = 'YEARLY_INCOME_DECLARATION',
    PROFIT_DISTRIBUTION_DECISION = 'PROFIT_DISTRIBUTION_DECISION',
    MERGED_CONTRACT = 'MERGED_CONTRACT',
    UC_REPORT = 'UC_REPORT',
    PRELIMINARY_PURCHASE_AGREEMENT = 'PRELIMINARY_PURCHASE_AGREEMENT',
    CONTRACTOR_AGREEMENT = 'CONTRACTOR_AGREEMENT',
    RENOVATION_ESTIMATION = 'RENOVATION_ESTIMATION',
    REFINANCING_CONTRACT = 'REFINANCING_CONTRACT',
    CONSTRUCTION_PERMIT = 'CONSTRUCTION_PERMIT',
    CONSTRUCTION_PROJECT = 'CONSTRUCTION_PROJECT',
    COMMUNICATIONS_PROJECT = 'COMMUNICATIONS_PROJECT',
    CONSTRUCTION_ESTIMATION = 'CONSTRUCTION_ESTIMATION',
    COMPETITOR_OFFER = 'COMPETITOR_OFFER',
    UNMAPPED = 'UNMAPPED',
    DEED_OF_DELIVERY = 'DEED_OF_DELIVERY',
    PURCHASE_AGREEMENT = 'PURCHASE_AGREEMENT',
    SIGNED_CONTRACT_CHANGE = 'SIGNED_CONTRACT_CHANGE',
}

export enum AssetName {
    RESIDENTIAL_LAND = 'RESIDENTIAL_LAND',
    AGRICULTURAL_LAND = 'AGRICULTURAL_LAND',
    FOREST_LAND = 'FOREST_LAND',
    COMMERCIAL_LAND = 'COMMERCIAL_LAND',
    OTHER_LAND = 'OTHER_LAND',
    FLAT = 'FLAT',
    HOUSE = 'HOUSE',
    SEMI_DETACHED_HOUSE = 'SEMI_DETACHED_HOUSE',
    ROWHOUSE = 'ROWHOUSE',
    OTHER_PREMISES = 'OTHER_PREMISES',
    KREDEX_GUARANTEE = 'KREDEX_GUARANTEE',
    PRIVATE_PERSONS_SURETY = 'PRIVATE_PERSONS_SURETY',
    PASSENGER_CAR = 'PASSENGER_CAR',
    VAN = 'VAN',
    LIGHT_TRUCK = 'LIGHT_TRUCK',
    CARAVAN = 'CARAVAN',
    TRAILER = 'TRAILER',
    MOTORCYCLE = 'MOTORCYCLE',
    SNOWMOBILE = 'SNOWMOBILE',
    BOAT = 'BOAT',
    ATV = 'ATV',
    OTHER = 'OTHER',
    UNMAPPED = 'UNMAPPED',
}

export enum InterestType {
    '6m' = '6m',
    FIX = 'FIX',
    UNMAPPED = 'UNMAPPED'
}

export enum AssetCondition {
    NEW = 'NEW',
    USED = 'USED',
    RENOVATED = 'RENOVATED',
    NOT_RENOVATED = 'NOT_RENOVATED',
    UNMAPPED = 'UNMAPPED',
}

export enum CollateralMainType {
    RESIDENTIAL_REAL_ESTATE_COLLATERAL = 'RESIDENTIAL_REAL_ESTATE_COLLATERAL',
    OFFICES_AND_COMMERCIAL_PREMISES = 'OFFICES_AND_COMMERCIAL_PREMISES',
    COMMERCIAL_REAL_ESTATE_COLLATERAL = 'COMMERCIAL_REAL_ESTATE_COLLATERAL',
    SECURITIES = 'SECURITIES',
    LOANS = 'LOANS',
    TRADE_RECEIVABLES = 'TRADE_RECEIVABLES',
    LIFE_INSURANCE_POLICIES_PLEDGED = 'LIFE_INSURANCE_POLICIES_PLEDGED',
    OTHER_PHYSICAL_COLLATERALS = 'OTHER_PHYSICAL_COLLATERALS',
    CREDIT_DERIVATIVES = 'CREDIT_DERIVATIVES',
    FINANCIAL_GUARANTEES_OTHER_THAN_CREDIT_DERIVATIVES = 'FINANCIAL_GUARANTEES_OTHER_THAN_CREDIT_DERIVATIVES',
    GOLD = 'GOLD',
    CURRENCY_AND_DEPOSITS = 'CURRENCY_AND_DEPOSITS',
    EQUITY_AND_INVESTMENT_FUND_SHARES_OR_UNITS = 'EQUITY_AND_INVESTMENT_FUND_SHARES_OR_UNITS',
    OTHER_PROTECTION = 'OTHER_PROTECTION',
    LAND = 'LAND',
    UNMAPPED = 'UNMAPPED',
}

export enum AssetType {
    PREMISES = 'PREMISES',
    LAND = 'LAND',
    VEHICLES = 'VEHICLES',
    SURETY = 'SURETY',
    EQUIPMENT_AND_MACHINERY = 'EQUIPMENT_AND_MACHINERY',
    FINANCIAL_GUARANTEES_OTHER_THAN_CREDIT_DERIVATIVES = 'FINANCIAL_GUARANTEES_OTHER_THAN_CREDIT_DERIVATIVES',
    UNMAPPED = 'UNMAPPED',
}

export enum IncomeType {
    SALARY = 'SALARY',
    PARENTAL_BENEFIT = 'PARENTAL_BENEFIT',
    ALIMONY = 'ALIMONY',
    SOCIAL_BENEFIT = 'SOCIAL_BENEFIT',
    CHILD_BENEFIT = 'CHILD_BENEFIT',
    PENSION = 'PENSION',
    FOREIGN_SALARY = 'FOREIGN_SALARY',
    CIVIL_SERVANT = 'CIVIL_SERVANT',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
    RETIREMENT_PENSION = 'RETIREMENT_PENSION',
    DISABILITY_PENSION = 'DISABILITY_PENSION',
    BUSINESS = 'BUSINESS',
    SPORT_OR_PERFORMANCE = 'SPORT_OR_PERFORMANCE',
    AGRICULTURE = 'AGRICULTURE',
    DIVIDENDS = 'DIVIDENDS',
    RENT = 'RENT',
    DAILY_ALLOWANCES = 'DAILY_ALLOWANCES',
    SICKNESS_BENEFIT = 'SICKNESS_BENEFIT',
    OTHER = 'OTHER',
    UNMAPPED = 'UNMAPPED',
}

export enum MilitaryServiceStatus {
    COMPLETED = 'COMPLETED',
    NOT_COMPLETED = 'NOT_COMPLETED',
    FREED = 'FREED'
}

export enum EmploymentRelationshipType {
    TEMPORARY = 'TEMPORARY',
    PERMANENT = 'PERMANENT',
}

export enum FieldOfActivity {
    PUBLIC_SECTOR = 'PUBLIC_SECTOR',
    STUDENT = 'STUDENT',
    PRIVATE_SECTOR = 'PRIVATE_SECTOR',
    UNEMPLOYED = 'UNEMPLOYED',
    INDIVIDUAL_ACTIVITY = 'INDIVIDUAL_ACTIVITY',
    ENTREPRENEUR = 'ENTREPRENEUR',
    PENSIONER = 'PENSIONER',
    PARENTAL_LEAVE = 'PARENTAL_LEAVE',
    CONSCRIPT = 'CONSCRIPT',
    OTHER = 'OTHER',
    UNMAPPED = 'UNMAPPED',
}

export enum StatusState {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    UNMAPPED = 'UNMAPPED'
}

export enum PersonRole {
    APPLICANT = 'APPLICANT',
    DEPENDENT = 'DEPENDENT',
    OTHER = 'OTHER',
    COBORROWER = 'COBORROWER',
    SPOUSE = 'SPOUSE',
    COLLATERAL_PROVIDER = 'COLLATERAL_PROVIDER',
    SURETY_PROVIDER = 'SURETY_PROVIDER',
    SELLER = 'SELLER',
    REPRESENTATIVE = 'REPRESENTATIVE',
    CONTACT_PERSON = 'CONTACT_PERSON',
    BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
    SUPERVISORY_BOARD_MEMBER = 'SUPERVISORY_BOARD_MEMBER',
    MEMBER_MANAGEMENT_BOARD = 'MEMBER_MANAGEMENT_BOARD',
    UNMAPPED = 'UNMAPPED'
}

export enum IndustryCode {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
    I = 'I',
    J = 'J',
    K = 'K',
    L = 'L',
    M = 'M',
    N = 'N',
    O = 'O',
    P = 'P',
    Q = 'Q',
    R = 'R',
    S = 'S',
    T = 'T',
    U = 'U'
}

export enum LegalFormCode {
    EE101 = 'EE101',
    EE102 = 'EE102',
    EE201 = 'EE201',
    EE202 = 'EE202',
    EE203 = 'EE203',
    EE204 = 'EE204',
    EE205 = 'EE205',
    EE206 = 'EE206',
    EE207 = 'EE207',
    FI10 = 'FI10',
    FI11 = 'FI11',
    FI12 = 'FI12',
    FI13 = 'FI13',
    FI16 = 'FI16',
    FI17 = 'FI17',
    FI19 = 'FI19',
    FI20 = 'FI20',
    FI21 = 'FI21',
    FI22 = 'FI22',
    FI23 = 'FI23',
    FI24 = 'FI24',
    FI25 = 'FI25',
    FI26 = 'FI26',
    FI27 = 'FI27',
    FI28 = 'FI28',
    FI29 = 'FI29',
    FI30 = 'FI30',
    FI31 = 'FI31',
    FI32 = 'FI32',
    FI33 = 'FI33',
    FI35 = 'FI35',
    FI39 = 'FI39',
    FI40 = 'FI40',
    FI41 = 'FI41',
    FI42 = 'FI42',
    FI43 = 'FI43',
    FI44 = 'FI44',
    FI45 = 'FI45',
    FI46 = 'FI46',
    FI47 = 'FI47',
    FI48 = 'FI48',
    FI49 = 'FI49',
    FI50 = 'FI50',
    FI55 = 'FI55',
    FI60 = 'FI60',
    FI61 = 'FI61',
    FI62 = 'FI62',
    FI63 = 'FI63',
    FI99 = 'FI99',
    LT101 = 'LT101',
    LT102 = 'LT102',
    LT103 = 'LT103',
    LT104 = 'LT104',
    LT105 = 'LT105',
    LT106 = 'LT106',
    LT107 = 'LT107',
    LT108 = 'LT108',
    LT109 = 'LT109',
    LT110 = 'LT110',
    LT111 = 'LT111',
    LT201 = 'LT201',
    LT202 = 'LT202',
    LT203 = 'LT203',
    LT204 = 'LT204',
    LT205 = 'LT205',
    LT206 = 'LT206',
    LT207 = 'LT207',
    LT208 = 'LT208',
    LT209 = 'LT209',
    LT210 = 'LT210',
    LT211 = 'LT211',
    LT212 = 'LT212',
    LT213 = 'LT213',
    LT214 = 'LT214',
    LT215 = 'LT215',
    LT216 = 'LT216',
    LT301 = 'LT301',
    LV101 = 'LV101',
    LV103 = 'LV103',
    LV104 = 'LV104',
    LV105 = 'LV105',
    LV106 = 'LV106',
    LV107 = 'LV107',
    LV108 = 'LV108',
    LV109 = 'LV109',
    LV110 = 'LV110',
    LV111 = 'LV111',
    LV301 = 'LV301',
    LV302 = 'LV302',
    LV303 = 'LV303',
    LV401 = 'LV401',
    LV402 = 'LV402',
    LV403 = 'LV403',
    LV404 = 'LV404',
    LV405 = 'LV405',
    LV406 = 'LV406',
    LV701 = 'LV701',
    LV801 = 'LV801',
    LV802 = 'LV802',
    LV803 = 'LV803',
    LV804 = 'LV804',
    LV805 = 'LV805',
    LV806 = 'LV806',
    LV807 = 'LV807',
    LV808 = 'LV808',
    LV809 = 'LV809',
    LV810 = 'LV810',
    LV903 = 'LV903',
    XX = 'XX',
}

export enum PersonType {
    PHYSICAL = 'PHYSICAL',
    LEGAL = 'LEGAL',
    UNMAPPED = 'UNMAPPED',
}

export enum ProductName {
    CAR_LOAN = 'CAR_LOAN',
    CAR_LOAN_BUSINESS = 'CAR_LOAN_BUSINESS',
    REVOLVING_LOAN = 'REVOLVING_LOAN',
    SMALL_LOAN = 'SMALL_LOAN',
    PROJECT_LOAN = 'PROJECT_LOAN',
    EQUIPMENT_LEASING_BUSINESS = 'EQUIPMENT_LEASING_BUSINESS',
    CAR_LEASING_BUSINESS = 'CAR_LEASING_BUSINESS',
    CAR_FINANCE_LEASE = 'CAR_FINANCE_LEASE',
    CAR_HIRE_PURCHASE = 'CAR_HIRE_PURCHASE',
    CAR_HIRE_PURCHASE_COLLATERAL = 'CAR_HIRE_PURCHASE_COLLATERAL',
    COMP_HIRE_PURCHASE = 'COMP_HIRE_PURCHASE',
    REG_HIRE_PURCHASE = 'REG_HIRE_PURCHASE',
    REFINANCING_LOAN = 'REFINANCING_LOAN',
    REAL_ESTATE_LOAN = 'REAL_ESTATE_LOAN',
    RENOVATION_LOAN = 'RENOVATION_LOAN',
    MEDICAL_LOAN = 'MEDICAL_LOAN',
    HOUSING_LOAN = 'HOUSING_LOAN',
    OPERATING_LOAN = 'OPERATING_LOAN',
    CREDIT_LINE = 'CREDIT_LINE',
    DEVELOPMENT_LOAN = 'DEVELOPMENT_LOAN',
    INVESTMENT_LOAN = 'INVESTMENT_LOAN',
    MORTGAGE_LOAN = 'MORTGAGE_LOAN',
    SMALL_LOAN_BUSINESS = 'SMALL_LOAN_BUSINESS',
    LAND_LOAN = 'LAND_LOAN',
    CAR_HIRE_PURCHASE_BUSINESS = 'CAR_HIRE_PURCHASE_BUSINESS',
    UNMAPPED = 'UNMAPPED'
}

/**
 * @see https://www.bigbank.eu/en/faq/what-is-the-difference-between-hire-purchase-and-leasing/
 * */
/**
 * List of product types mapping between Product Registry and other systesms (including AS).
 * Note: This is not a full list, but should be populated systematically.
 *
 * @see {@link https://stash.big.local/projects/CORE/repos/loans/browse/api/src/main/java/ee/bigbank/loan/setup/product/type/ProductTypeCode.java} Full list of supported loans at Bigbank CORE.
 */
export enum ProductType {
    REG_HIRE_PURCHASE_FI01 = 'REG_HIRE_PURCHASE_FI01',
    REG_HIRE_PURCHASE_LT01 = 'REG_HIRE_PURCHASE_LT01',
    REG_HIRE_PURCHASE_LV01 = 'REG_HIRE_PURCHASE_LV01',
    REG_HIRE_PURCHASE_EE01 = 'REG_HIRE_PURCHASE_EE01',
    REG_HIRE_PURCHASE_BG01 = 'REG_HIRE_PURCHASE_BG01',
}

export enum LiabilityType {
    CONSUMER_LOAN = 'CONSUMER_LOAN',
    CAR_LOAN = 'CAR_LOAN',
    CREDIT_CARD = 'CREDIT_CARD',
    HIRE_PURCHASE = 'HIRE_PURCHASE',
    MORTGAGE = 'MORTGAGE',
    SHORT_TERM_LOAN = 'SHORT_TERM_LOAN',
    GUARANTEE = 'GUARANTEE',
    ALIMONY = 'ALIMONY',
    DEBT_COLLECTION = 'DEBT_COLLECTION',
    BUSINESS_LOAN = 'BUSINESS_LOAN',
    BUSINESS_LEASING = 'BUSINESS_LEASING',
    BUSINESS_SURETY = 'BUSINESS_SURETY',
    OVERDRAFT = 'OVERDRAFT',
    OTHER = 'OTHER',
    UNMAPPED = 'UNMAPPED',
}

export enum ApplicationOrigin {
    WEB = 'WEB',
    INTERNAL = 'INTERNAL',
    PARTNER = 'PARTNER',
    BROKER_API = 'BROKER_API',
    PARTNER_API = 'PARTNER_API',
    PARTNER_SHORT = 'PARTNER_SHORT',
    PARTNER_HOSTED_FLOW = 'PARTNER_HOSTED_FLOW',
    PARTNER_REMOTE = 'PARTNER_REMOTE',
    PARTNER_LANDING_PAGE = 'PARTNER_LANDING_PAGE',
    UNMAPPED = 'UNMAPPED',
}

export enum RoleCode {
    BB_ADMIN = 'BB_ADMIN',
    BB_MANAGER = 'BB_MANAGER',
    BB_EMPLOYEE = 'BB_EMPLOYEE',
    PARTNER_ADMIN = 'PARTNER_ADMIN',
    PARTNER_MANAGER = 'PARTNER_MANAGER',
    PARTNER_EMPLOYEE = 'PARTNER_EMPLOYEE',
    PARTNER_AUDIT = 'PARTNER_AUDIT',
    PARTNER_ACCOUNTANT = 'PARTNER_ACCOUNTANT',
    BIGBANK_ADMIN = 'BIGBANK_ADMIN',
    BIGBANK_MANAGER = 'BIGBANK_MANAGER',
    PUBLIC = 'PUBLIC',
    BIGBANK_SUPER_ADMIN = 'BIGBANK_SUPER_ADMIN',
    BIGBANK_LOAN_SPECIALIST = 'BIGBANK_LOAN_SPECIALIST',
    PARTNER_NETS_ADMIN = 'PARTNER_NETS_ADMIN',
    PARTNER_NETS_REGULAR = 'PARTNER_NETS_REGULAR',
    UNMAPPED = 'UNMAPPED',
}

export enum SourceChannel {
    WEBPAGE = 'WEBPAGE',
    SELF_SERVICE = 'SELF_SERVICE',
    CALL_CENTER = 'CALL_CENTER',
    OFFICE = 'OFFICE',
    MOBILE_APP = 'MOBILE_APP',
    UNMAPPED = 'UNMAPPED',
}

export enum InvitationTypes {
    EMAIL = 'EMAIL',
    SMS = 'SMS'
}

export enum MfaTypeCodes {
    SMS = 'SMS',
    EMAIL = 'EMAIL'
}

export enum BonusApplicationStatuses {
    RECEIVED = 'RECEIVED',
    ANNULLED = 'ANNULLED',
    CONFIRMED = 'CONFIRMED'
}

export enum ApplicationTypes {
    PARTNER_APPLICATION = 'PARTNER_APPLICATION',
    REMOTE_APPLICATION = 'REMOTE_APPLICATION',
    CAR_LEASING_APPLICATION = 'CAR_LEASING_APPLICATION',
    SHORT_APPLICATION = 'SHORT_APPLICATION',
}

export enum ChannelTypes {
    WEBPAGE = 'WEBPAGE',
    OFFICE = 'OFFICE',
    CALLCENTER = 'CALLCENTER',
    BROKER = 'BROKER',
    MOBILEAPP = 'MOBILEAPP',
    SELFSERVICE = 'SELFSERVICE',
    UNMAPPED = 'UNMAPPED',
}

export enum ApplicationStatus {
    INCOMPLETE = 'INCOMPLETE',
    MANUAL_PROCEEDING = 'MANUAL_PROCEEDING',
    COMPLETE = 'COMPLETE',
    ANNULLED = 'ANNULLED',
    EXPIRED = 'EXPIRED',
    UNMAPPED = 'UNMAPPED',
}

export enum PartnerType {
    BROKER = 'BROKER',
    HIRE_PURCHASE = 'HIRE_PURCHASE',
    COMPARISON_SITE = 'COMPARISON_SITE',
    CALL_CENTER = 'CALL_CENTER',
    TELEMARKETER = 'TELEMARKETER',
    API = 'API',
    PARTNER_AFFILIATE = 'PARTNER_AFFILIATE',
    NETS = 'NETS',
    UNMAPPED = 'UNMAPPED'
}

export enum RouteNames {
    LOGIN = 'Login',
    INVITATION = 'Invitation',
    REGISTER = 'Register',
    RESET_PASSWORD = 'Reset Password',
    ENTITIES = 'Entities',
    CHANNEL = 'Channel',
    USER = 'User',
    EDIT_CHANNEL = 'Edit Channel',
    EDIT_USER = 'Edit User',
    COMMISSIONS = 'Commissions',
    APPLICATIONS = 'Applications',
    INVITATIONS = 'Invitations',
    PROFILE = 'Profile',
    PASSWORD = 'Password',
    GIFT_CARDS = 'Gift Cards',
    BONUS_APPLICATIONS = 'Bonus Applications',
    DOCUMENTS = 'Documents',
    INDICATIVE_CALCULATOR = 'Indicative Calculator',
    APPLICATION = 'Application',
    ERROR = 'Error',
    ANALYSING_DATA = 'Analysing Data',
    RESIDUALS = 'Residuals',
    PAID_COMMISSIONS_REPORT = 'Paid commissions report',
    AML_TEST = 'AML test',

    // internal
    CORPORATION = 'Corporation',
    PARTNER = 'Partner',
    EDIT_PARTNER = 'Edit Partner',
    EDIT_CORPORATION = 'Edit Corporation',
    ADD_COMMISSION = 'Add Commission',
    USER_BONUSES = 'User Bonuses',
    ADD_USER_BONUS = 'Add User Bonus',
    GIFT_CARD_MANAGEMENT = 'Gift Card Management',
    USER_BONUS_REPORT = 'User Bonus Report',
    ADD_PARTNER_USER_BONUS = 'Add Partner User Application Bonus',
    MANAGE_DOCUMENTS = 'Manage Documents',
    ADD_DOCUMENT = 'Add Document',
    PARTNERS_REPORT = 'Partners Report',
    USERS_REPORT = 'Users Report',
    QUESTIONNAIRE_REPORT = 'Questionnaire Report',
    MANAGE_QUESTIONNAIRE = 'Manage Questionnaire',
    ADD_QUESTION = 'Add Question',
    EDIT_QUESTION = 'Edit Question',
    BANNERS = 'BANNERS',

    // LT
    DOCUMENT_ARCHIVING = 'Document Archiving',
    MOBILE_START_PAGE = 'Mobile Start Page',
    CONTRACT_SIGNED_THANK_YOU_PAGE = 'Contract Signed Thank You Page',
    DIGITAL_CHOSEN_FOR_CONTRACT_THANK_YOU_PAGE = 'DigitalChosenForContractSigningThankYouPage',
    SIGN_LOAN_TASK = 'Sign Loan Task',
    SIGN_PROMISE_TO_PAY_TASK = 'Sign Promise to pay',
    PARTNER_PRODUCTS = 'Partner Products',
    THANK_YOU_PAGE = 'Thank you page',
    UNAVAILABLE = 'Unavailable',

    // Internet Bank access blocking
    ACCESS_BLOCKING = 'Access Blocking',
}

export enum CommissionTypeCode {
    PERCENTAGE = 'PERCENTAGE',
    INTEREST_INCOME = 'INTEREST_INCOME',
    FIXED_SUM = 'FIXED_SUM',
    SALE_BASED_PERCENTAGE = 'SALE_BASED_PERCENTAGE',
    LOAN_AMOUNT = 'LOAN_AMOUNT',
    LOAN_PERIOD = 'LOAN_PERIOD',
    ADMIN_FEE = 'ADMIN_FEE',
    CUSTOM = 'CUSTOM',
}

export enum ResidenceType {
    PRIVATE_APARTMENT = 'PRIVATE_APARTMENT',
    PRIVATE_HOUSE = 'PRIVATE_HOUSE',
    RENTAL_PREMISES = 'RENTAL_PREMISES',
    RENTAL_ROOM = 'RENTAL_ROOM',
    LIVING_WITH_PARENTS = 'LIVING_WITH_PARENTS',
    PART_OWNERSHIP = 'PART_OWNERSHIP',
    RIGHT_OF_RESIDENCE = 'RIGHT_OF_RESIDENCE',
    OTHER = 'OTHER',
    UNMAPPED = 'UNMAPPED',
}

export enum Education {
    BASIC = 'BASIC',
    SECONDARY_SPECIALISED = 'SECONDARY_SPECIALISED',
    SECONDARY = 'SECONDARY',
    HIGHER = 'HIGHER',
    HIGHER_BA = 'HIGHER_BA',
    HIGHER_MA = 'HIGHER_MA',
    HIGHER_PHD = 'HIGHER_PHD',
    UNMAPPED = 'UNMAPPED',
}

export enum Domain {
    EE = 'EE',
    LT = 'LT',
    FI = 'FI',
    LV = 'LV',
    INTERNAL = 'INTERNAL',
    UNDEFINED = 'UNDEFINED'
}

export const DomainToOrganization = {
  [Domain.EE]: 'EEBIG',
  [Domain.LT]: 'LTBIG',
  [Domain.LV]: 'LVBIG',
  [Domain.FI]: 'FIBIG',
};

export enum Languages {
    en = 'en',
    lt = 'lt',
    fi = 'fi',
    lv = 'lv',
    et = 'et',
}

export enum VerificationMethods {
    MOBILE_ID = 'MOBILE_ID',
    SMART_ID = 'SMART_ID',
    ID_CARD = 'ID_CARD'
}

export enum ApplicantDeclaredIncomeType {
    SALARY = 'SALARY',
    PARENTAL_BENEFIT = 'PARENTAL_BENEFIT',
    ALIMONY = 'ALIMONY',
    SOCIAL_BENEFIT = 'SOCIAL_BENEFIT',
    CHILD_BENEFIT = 'CHILD_BENEFIT',
    PENSION = 'PENSION',
    RETIREMENT_PENSION = 'RETIREMENT_PENSION',
    DISABILITY_PENSION = 'DISABILITY_PENSION',
    BUSINESS = 'BUSINESS',
    DIVIDENDS = 'DIVIDENDS',
    RENT = 'RENT',
    DAILY_ALLOWANCES = 'DAILY_ALLOWANCES',
    SICKNESS_BENEFIT = 'SICKNESS_BENEFIT',
    OTHER = 'OTHER',
    UNMAPPED = 'UNMAPPED',
}

export enum MaritalStatus {
    DIVORCED = 'DIVORCED',
    MARRIED = 'MARRIED',
    COHABITING = 'COHABITING',
    SINGLE = 'SINGLE',
    WIDOW = 'WIDOW',
    UNMAPPED = 'UNMAPPED',
}

export enum DocumentTypes {
    ID_CARD = 'ID_CARD',
    PASSPORT = 'PASSPORT',
    PERMANENT_RESIDENCE_PERMIT_CARD = 'PERMANENT_RESIDENCE_PERMIT_CARD',
    TEMPORARY_RESIDENCE_PERMIT_CARD = 'TEMPORARY_RESIDENCE_PERMIT_CARD',
    EU_RESIDENCE_PERMIT_CARD = 'EU_RESIDENCE_PERMIT_CARD',
    DATA_PROCESSING_CONSENT = 'DATA_PROCESSING_CONSENT',
    DATA_PROCESSING_AND_MARKETING_CONSENT = 'DATA_PROCESSING_AND_MARKETING_CONSENT',
    DATA_PROCESSING_PRINCIPLES = 'DATA_PROCESSING_PRINCIPLES',
    HIRE_PURCHASE_MANUAL = 'HIRE_PURCHASE_MANUAL',
    PARTNER_SYSTEM_MANUAL = 'PARTNER_SYSTEM_MANUAL',
    LOAN_APPLICATION = 'LOAN_APPLICATION',
    FOURTEEN_DAY_RETURN = 'FOURTEEN_DAY_RETURN',
    UNMAPPED = 'UNMAPPED',
}

export enum ApplicationKeys {
    CHANNEL = 'channel',
    USERNAME = 'userName',
    CREATION_DATETIME = 'creationDateTime',
    APPLICATION_NUMBER = 'applicationNumber',
    STATUS = 'status',
    FIRST_NAME = 'firstName',
    SURNAME = 'surname',
    PERSONAL_IDENTITY_CODE = 'personalIdentityCode',
    PRODUCT_NAME = 'productName',
    LOAN_AMOUNT = 'loanAmount',
    SELF_FINANCING_AMOUNT = 'selfFinancingAmount',
    GOODS_PRICE = 'goodsPrice',
    GOODS_NAME = 'goodsName',
    CONTRACT_NUMBER = 'contractNumber',
    CONTRACT_SIGNING_DATETIME = 'contractSigningDateTime',
    CALCULATED_BONUS_AMOUNT = 'calculatedBonusAmount',
    PERSONAL_DATA = 'personalData',
    COMMISSION_AMOUNT = 'commissionAmount',
    FULL_NAME = 'fullName',
    CONTRACT_LINK = 'contractLink',
    BRAND = 'brand',
    MODEL = 'model',
    REGISTRATION_NUMBER = 'registrationNumber',
    ID_DOCUMENT_TYPE = 'idDocumentType',
    PARTNER = 'partner',
    SEGMENT_NAME = 'segmentName',
    CONSENT_ID = 'consentId',
    ID_DOCUMENT_SCANNED = 'idDocumentScanned',
    CONSENT_METHOD_COPIED_FROM = 'consentMethodCopiedFrom',
    REJECTION_REASONS = 'rejectionReasons',
    EXTERNAL_ID = 'externalId',
    CONTRACT_STATUS = 'contractStatus',
    CONTRACT_SIGNING_METHOD = 'contractSigningMethod',
    SPOUSE_FIRSTNAME = 'spouseFirstName',
    SPOUSE_SURNAME = 'spouseSurname',
    SPOUSE_PERSONAL_IDENTITY_CODE = 'spousePersonalIdentityCode',
    SPOUSE_CONSENT_METHOD = 'spouseConsentMethod',
    SPOUSE_CONSENT_DATETIME = 'spouseConsentDateTime',
    SPOUSE_CONSENT_METHOD_COPIED_FROM = 'spouseConsentMethodCopiedFrom',
    PARTNER_USER_ID = 'partnerUserId',
    COPY = 'copy',
    HAS_SIGNED_CONTRACT = 'hasSignedContract',
    EDIT = 'editApplication',
    CHANNEL_ID = 'channelId',

    // CONTRACT_CHANGES
    TYPE = 'type',
    PLANNED_SIGNING_DATE = 'plannedSigningDate',
    CHANGE_TYPE_CODE = 'changeTypeCode',
    CUSTOMER_ID = 'customerId',
    HEADER_ID = 'headerId',
    VERSION_ID = 'versionId',

    // PROMISE_TO_PAY
    PROMISE_TO_PAY_ID = 'promiseToPayId',

}

export enum CommonTaskDefinitionKeys {
    NEW_CAR_LEASING_APPLICATION = 'newCarLeasingApplication',
    NEW_CORPORATE_APPLICATION = 'newCorporateApplication',
    NEW_PARTNER_APPLICATION = 'newPartnerApplication',
    NEW_REGULAR_HIRE_PURCHASE_APPLICATION = 'newRegularHirePurchaseApplication',
    NEW_REMOTE_APPLICATION = 'newRemoteApplication',
    NEW_SHORT_APPLICATION = 'newShortApplication'
}

export enum ESigningState {
    IN_REVIEW = 'contractInReview',
    APPLICANT_SIGNING = 'applicantSigning',
    PARTNER_SIGNING = 'partnerSigning',
    CO_APPLICANT_SIGNING = 'coApplicantSigning',
}

export enum TransformType {
    OUT = 'OUT',
    IN = 'IN'
}

export enum CountryCode {
    AD = 'AD',
    AE = 'AE',
    AF = 'AF',
    AG = 'AG',
    AI = 'AI',
    AL = 'AL',
    AM = 'AM',
    AO = 'AO',
    AQ = 'AQ',
    AR = 'AR',
    AS = 'AS',
    AT = 'AT',
    AU = 'AU',
    AW = 'AW',
    AX = 'AX',
    AZ = 'AZ',
    BA = 'BA',
    BB = 'BB',
    BD = 'BD',
    BE = 'BE',
    BF = 'BF',
    BG = 'BG',
    BH = 'BH',
    BI = 'BI',
    BJ = 'BJ',
    BL = 'BL',
    BM = 'BM',
    BN = 'BN',
    BO = 'BO',
    BQ = 'BQ',
    BR = 'BR',
    BS = 'BS',
    BT = 'BT',
    BV = 'BV',
    BW = 'BW',
    BY = 'BY',
    BZ = 'BZ',
    CA = 'CA',
    CC = 'CC',
    CD = 'CD',
    CF = 'CF',
    CG = 'CG',
    CH = 'CH',
    CI = 'CI',
    CK = 'CK',
    CL = 'CL',
    CM = 'CM',
    CN = 'CN',
    CO = 'CO',
    CR = 'CR',
    CU = 'CU',
    CV = 'CV',
    CW = 'CW',
    CX = 'CX',
    CY = 'CY',
    CZ = 'CZ',
    DE = 'DE',
    DJ = 'DJ',
    DK = 'DK',
    DM = 'DM',
    DO = 'DO',
    DZ = 'DZ',
    EC = 'EC',
    EE = 'EE',
    EG = 'EG',
    EH = 'EH',
    ER = 'ER',
    ES = 'ES',
    ET = 'ET',
    FI = 'FI',
    FJ = 'FJ',
    FK = 'FK',
    FM = 'FM',
    FO = 'FO',
    FR = 'FR',
    GA = 'GA',
    GB = 'GB',
    GD = 'GD',
    GE = 'GE',
    GF = 'GF',
    GG = 'GG',
    GH = 'GH',
    GI = 'GI',
    GL = 'GL',
    GM = 'GM',
    GN = 'GN',
    GP = 'GP',
    GQ = 'GQ',
    GR = 'GR',
    GS = 'GS',
    GT = 'GT',
    GU = 'GU',
    GW = 'GW',
    GY = 'GY',
    HK = 'HK',
    HM = 'HM',
    HN = 'HN',
    HR = 'HR',
    HT = 'HT',
    HU = 'HU',
    ID = 'ID',
    IE = 'IE',
    IL = 'IL',
    IM = 'IM',
    IN = 'IN',
    IO = 'IO',
    IQ = 'IQ',
    IR = 'IR',
    IS = 'IS',
    IT = 'IT',
    JE = 'JE',
    JM = 'JM',
    JO = 'JO',
    JP = 'JP',
    KE = 'KE',
    KG = 'KG',
    KH = 'KH',
    KI = 'KI',
    KM = 'KM',
    KN = 'KN',
    KP = 'KP',
    KR = 'KR',
    KW = 'KW',
    KY = 'KY',
    KZ = 'KZ',
    LA = 'LA',
    LB = 'LB',
    LC = 'LC',
    LI = 'LI',
    LK = 'LK',
    LR = 'LR',
    LS = 'LS',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    LY = 'LY',
    MA = 'MA',
    MC = 'MC',
    MD = 'MD',
    ME = 'ME',
    MF = 'MF',
    MG = 'MG',
    MH = 'MH',
    MK = 'MK',
    ML = 'ML',
    MM = 'MM',
    MN = 'MN',
    MO = 'MO',
    MP = 'MP',
    MQ = 'MQ',
    MR = 'MR',
    MS = 'MS',
    MT = 'MT',
    MU = 'MU',
    MV = 'MV',
    MW = 'MW',
    MX = 'MX',
    MY = 'MY',
    MZ = 'MZ',
    NA = 'NA',
    NC = 'NC',
    NE = 'NE',
    NF = 'NF',
    NG = 'NG',
    NI = 'NI',
    NL = 'NL',
    NO = 'NO',
    NP = 'NP',
    NR = 'NR',
    NU = 'NU',
    NZ = 'NZ',
    OM = 'OM',
    PA = 'PA',
    PE = 'PE',
    PF = 'PF',
    PG = 'PG',
    PH = 'PH',
    PK = 'PK',
    PL = 'PL',
    PM = 'PM',
    PN = 'PN',
    PR = 'PR',
    PS = 'PS',
    PT = 'PT',
    PW = 'PW',
    PY = 'PY',
    QA = 'QA',
    RE = 'RE',
    RO = 'RO',
    RS = 'RS',
    RU = 'RU',
    RW = 'RW',
    SA = 'SA',
    SB = 'SB',
    SC = 'SC',
    SD = 'SD',
    SE = 'SE',
    SG = 'SG',
    SH = 'SH',
    SI = 'SI',
    SJ = 'SJ',
    SK = 'SK',
    SL = 'SL',
    SM = 'SM',
    SN = 'SN',
    SO = 'SO',
    SR = 'SR',
    SS = 'SS',
    ST = 'ST',
    SV = 'SV',
    SX = 'SX',
    SY = 'SY',
    SZ = 'SZ',
    TC = 'TC',
    TD = 'TD',
    TF = 'TF',
    TG = 'TG',
    TH = 'TH',
    TJ = 'TJ',
    TK = 'TK',
    TL = 'TL',
    TM = 'TM',
    TN = 'TN',
    TO = 'TO',
    TR = 'TR',
    TT = 'TT',
    TV = 'TV',
    TW = 'TW',
    TZ = 'TZ',
    UA = 'UA',
    UG = 'UG',
    UM = 'UM',
    US = 'US',
    UY = 'UY',
    UZ = 'UZ',
    VA = 'VA',
    VC = 'VC',
    VE = 'VE',
    VG = 'VG',
    VI = 'VI',
    VN = 'VN',
    VU = 'VU',
    WF = 'WF',
    WS = 'WS',
    YE = 'YE',
    YT = 'YT',
    ZA = 'ZA',
    ZM = 'ZM',
    ZW = 'ZW',
    UNMAPPED = 'UNMAPPED'
}

export enum ApplicationType {
    CONTRACT_CHANGE = 'CONTRACT_CHANGE',
    PROMISE_TO_PAY = 'PROMISE_TO_PAY'
}

export enum IdentificationMethod {
    FACE_TO_FACE = 'FACE_TO_FACE',
    FACE_TO_FACE_3RD = 'FACE_TO_FACE_3RD',
    VIDEO_DIRECTLY = 'VIDEO_DIRECTLY',
    DISTANCE = 'DISTANCE',
    DISTANCE_3RD = 'DISTANCE_3RD',
    UNMAPPED = 'UNMAPPED',
}

export enum DecisionRule {
    CUSTOMER_AML_RISK_LEVEL_HIGH = 'customerAmlRiskLevelHigh',
    ACTIVE_EXTERNAL_PAYMENT_DEFAULT = 'activeExternalPaymentDefault',
    ENDED_EXTERNAL_PAYMENT_DEFAULT = 'endedExternalPaymentDefault',
    EXISTING_LOAN_IN_DEBT = 'existingLoanInDebt',
    EXISTING_LOAN_IN_DEBT_COLLECTION = 'existingLoanInDebtCollection',
    FORMER_CUSTOMER_DEBT_COLLECTION_PROCEEDING = 'formerCustomerDebtCollectionProceeding',
    FORMER_CUSTOMER_LITIGATION_PROCEEDING = 'formerCustomerLitigationProceeding',
    FORMER_CUSTOMER_OTHER_PROCEEDING = 'formerCustomerOtherProceeding',
    CUSTOMER_HAS_WRITE_OFF_LOAN_IN_BIGBANK = 'customerHasWriteOffLoanInBigbank',
    EXISTING_LOAN_AGREEMENT_NEGATIVE = 'existingLoanAgreementNegative',
    NO_LIVING_PERMIT = 'noLivingPermit',
    ADDRESS_ABROAD = 'addressAbroad',
    CUSTOMER_MAX_AGE = 'customerMaxAge',
    CUSTOMER_MIN_AGE = 'customerMinAge',
    CUSTOMER_DTI_OVER_LIMIT = 'customerDtiOverLimit',
    CUSTOMER_RESERVE_NEGATIVE = 'customerReserveNegative',
    CUSTOMER_MAX_LOAN_SUM_BELOW_LIMIT = 'customerMaxLoanSumBelowLimit',
    EXISTING_LOAN_FIRST_PAYMENT_NOT_DONE = 'existingLoanFirstPaymentNotDone',
    INCOME_TYPE_SOCIAL_BENEFIT = 'incomeTypeSocialBenefit',
    NET_INCOME_UNDER_MIN_INDIVIDUAL = 'netIncomeUnderMinIndividual',
    CUSTOMER_UNEMPLOYED = 'customerUnemployed',
}

export enum ManualDecisionRule {
    CUSTOMER_INCOME_CONFIRMATION_NEEDED = 'customerIncomeConfirmationNeeded',
    APPROVED_ADDITIONAL_CHECK = 'approvedAdditionalCheck',
    ANALYSING_AFTER_SUBMIT = 'analysingAfterSubmit',
}

export enum PreferredSigningMethod {
    WRITTEN = 'WRITTEN',
    DIGITAL = 'DIGITAL',
    UNMAPPED = 'UNMAPPED',
}

export enum CalculatorType {
    INDICATIVE = 'INDICATIVE',
    OFFER = 'OFFER'
}

export enum Dependents {
    FIVE_OR_MORE = '5_OR_MORE',
}

export enum EntityStatus {
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
    RETIRED = 'RETIRED',
    AML_TRIES_EXCEEDED = 'AML_TRIES_EXCEEDED',
    INVALID_LOGINS_EXCEEDED = 'INVALID_LOGINS_EXCEEDED',
}

export enum FIBeneficialOwnerCode {
    HAS_OWNERS = 'THE_COMPANY_HAS_BENEFICIAL_OWNERS',
    NO_OWNERS_REGISTERED = '',
    CAN_NOT_BE_CONCLUDED = 'THE_BENEFICIAL_OWNERS_OF_THE_COMPANY_CANNOT_BE_CONCLUDED',
}

export enum RepaymentOrigin {
    PROPERTY_SALE = 'PROPERTY_SALE',
    RETURN_ON_INVESTMENT = 'RETURN_ON_INVESTMENT',
    INCOME_BUSINESS_ACTIVITY = 'INCOME_BUSINESS_ACTIVITY',
    OTHER = 'OTHER',
}

export enum DeclaredAmountSource {
    CUSTOMER_DECLARED = 'CUSTOMER_DECLARED'
}
