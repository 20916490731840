import { IOptionsFromEnum } from '@/types/common/interfaces';
import {
  AssetName,
  FieldOfActivity,
  GoodsCategory,
  IdDocumentType,
  IncomeType,
  LegalFormCode,
  MaritalStatus,
  MilitaryServiceStatus,
  PersonRole,
  RepaymentOrigin,
  ResidenceType,
} from '@/types/common/enums';

export const internalDateDisplayFormat = 'DD.MM.YYYY';

export const fiCountryCode = '+358';

export const fiLoanPeriodMin = 6;

export const fiLoanPeriodMax = 96;

export const monthlyPaymentDayOptions: number[] = [
  1,
  5,
  10,
  15,
  20,
  25,
];

export const identificationDocumentOptions: IOptionsFromEnum<IdDocumentType> = {
  driversLicence: IdDocumentType.DRIVERS_LICENSE,
  idCard: IdDocumentType.ID_CARD,
  passport: IdDocumentType.PASSPORT,
  unmapped: IdDocumentType.UNMAPPED,
};

export const hasDrivingLicenceOptions = [
  {
    text: 'Yes',
    value: 'true',
  },
  {
    text: 'No',
    value: 'false',
  },
];

export const maritalStatusOptions: IOptionsFromEnum<MaritalStatus> = {
  single: MaritalStatus.SINGLE,
  cohabiting: MaritalStatus.COHABITING,
  divorced: MaritalStatus.DIVORCED,
  widow: MaritalStatus.WIDOW,
  married: MaritalStatus.MARRIED,
};

export const vehicleTypeOptions: IOptionsFromEnum<AssetName> = {
  passengerCar: AssetName.PASSENGER_CAR,
  van: AssetName.VAN,
  caravan: AssetName.CARAVAN,
  trailer: AssetName.TRAILER,
  motorcycle: AssetName.MOTORCYCLE,
  boat: AssetName.BOAT,
  other: AssetName.OTHER,
};

/**
 * Enum-like object representing financed product types and their corresponding
 * application service mappings. These values most likely correspond to
 * underlying service categories in the application.
 *
 * @see {@link https://stash.big.local/projects/LA/repos/application-service-v2/browse/application-model/src/main/java/eu/bigbank/application/enums/GoodsAndServicesCategory.java} AS Swagger Documentation
 */
export const financedProductTypeOptions: IOptionsFromEnum<GoodsCategory> = {
  downPaymentRemainingDebtDifference: GoodsCategory.FINANCIAL,
  unregisteredVehicle: GoodsCategory.UNREGISTERED_VEHICLE,
  vehicleAccessories: GoodsCategory.VEHICLE_ACCESSORIES,
  recreationalVehicleAccessories: GoodsCategory.MOTOHOME_ACCESSORIES,
  boatsAndWatercraft: GoodsCategory.WATERCRAFT,
  workMachinesAndTools: GoodsCategory.EQUIPMENT_AND_MACHINERY,
  leisureAndHobbyEquipment: GoodsCategory.HOBBY,
  other: GoodsCategory.UNMAPPED,
};

export const fieldOfActivityOptions: IOptionsFromEnum<FieldOfActivity> = {
  privateSector: FieldOfActivity.PRIVATE_SECTOR,
  entrepreneur: FieldOfActivity.ENTREPRENEUR,
  unemployed: FieldOfActivity.UNEMPLOYED,
  student: FieldOfActivity.STUDENT,
  pensioner: FieldOfActivity.PENSIONER,
  individualActivity: FieldOfActivity.INDIVIDUAL_ACTIVITY,
};

export const residenceTypeOptions: IOptionsFromEnum<ResidenceType> = {
  privateApartment: ResidenceType.PRIVATE_APARTMENT,
  partOwnership: ResidenceType.PART_OWNERSHIP,
  rentalPremises: ResidenceType.RENTAL_PREMISES,
  rightOfResidence: ResidenceType.RIGHT_OF_RESIDENCE,
  livingWithParents: ResidenceType.LIVING_WITH_PARENTS,
  other: ResidenceType.OTHER,
};

export const militaryServiceOptions: IOptionsFromEnum<MilitaryServiceStatus> = {
  completed: MilitaryServiceStatus.COMPLETED,
  notCompleted: MilitaryServiceStatus.NOT_COMPLETED,
  freed: MilitaryServiceStatus.FREED,
};

export const incomeTypeOptions: IOptionsFromEnum<IncomeType> = {
  salary: IncomeType.SALARY,
  pension: IncomeType.PENSION,
};

export const offerDecisions = {
  APPROVED: 'Approved',
  APPROVED_ADDITIONAL_CHECK: 'ApprovedAdditionalCheck',
};

export const rejectedReasons = [
  'REJECTED_AUTO',
  'SUSPECTED_FRAUD',
  'EMPLOYMENT_RELATED_ISSUES',
  'ENTREPRENEUR_RELATED_ISSUES',
  'AML',
  'HIGH_RISK',
  'OTHER_ISSUES',
  'SANCTIONED_PERSON',
  'PEP',
  'GAMBLING',
  'MANY_FAST_LOANS',
  'COOPERATION_HISTORY',
];

export const personalCodeControlCharacters = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'H',
  'J',
  'K',
  'L',
  'M',
  'N',
  'P',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
];

export const representativePersonRoles = [
  PersonRole.REPRESENTATIVE,
  PersonRole.SUPERVISORY_BOARD_MEMBER,
  PersonRole.MEMBER_MANAGEMENT_BOARD,
];

export const repaymentOriginOptions: IOptionsFromEnum<RepaymentOrigin> = {
  propertySale: RepaymentOrigin.PROPERTY_SALE,
  returnOnInvestment: RepaymentOrigin.RETURN_ON_INVESTMENT,
  incomeBusinessActivity: RepaymentOrigin.INCOME_BUSINESS_ACTIVITY,
  other: RepaymentOrigin.OTHER,
};

export const yehLegalFormCode = LegalFormCode.FI19;

