import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VeeValidate from 'vee-validate';
import './utils/common/request';
import '@bigbank/interface-components/dist/lib/esm.css';
import auth from './utils/common/auth';
import installNotification from './utils/common/installNotification';
import installPrivileges from './utils/common/installPriviliges';
import i18n from './utils/common/i18n';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import VueMeta from 'vue-meta';

import validationMessagesEn from 'vee-validate/dist/locale/en';
import validationMessagesLt from 'vee-validate/dist/locale/lt';
import validationMessagesEt from 'vee-validate/dist/locale/et';
import validationMessagesLv from 'vee-validate/dist/locale/lv';
import validationMessagesFi from 'vee-validate/dist/locale/fi';
import { Domain, DomainToOrganization, RoleCode } from './types/common/enums';
import { findInputMask } from './utils/common/findInputMask';
import { installState } from './utils/common/installState';
import { getSelectedDomain } from '@/utils/common/getSelectedDomain';
import { UserData } from '@/types/types';
import { request } from '@/utils/common/request';
import { set } from 'lodash-es';
import MountingPortal from 'portal-vue';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Vue.use(VeeValidate, {
  i18nRootKey: 'validations',
  inject: false,
  i18n,
  dictionary: {
    en: validationMessagesEn,
    lt: validationMessagesLt,
    et: validationMessagesEt,
    lv: validationMessagesLv,
    fi: validationMessagesFi,
  },
});
Vue.use(VueMeta);
Vue.use(MountingPortal);

if (window.config.DOMAIN === Domain.FI) {
  Vue.use(VueGtag, {
    config: {
      id: window.config.GOOGLE_ANALYTICS_ID,
    },
  });
}

installPrivileges(Vue);
installNotification(Vue);
installState(Vue);

Vue.prototype.$userData = () => {
  return localStorage.user ? JSON.parse(localStorage.user) as UserData : {};
};

Vue.prototype.$eventHub = new Vue(); // Global event bus

Vue.prototype.$selectedDomain = () => getSelectedDomain();
Vue.prototype.$domain = () => { return window.config.DOMAIN; };
Vue.prototype.$isEe = () => { return getSelectedDomain() === Domain.EE; };
Vue.prototype.$isLt = () => { return getSelectedDomain() === Domain.LT; };
Vue.prototype.$isLv = () => { return getSelectedDomain() === Domain.LV; };
Vue.prototype.$isFi = () => { return getSelectedDomain() === Domain.FI; };
Vue.prototype.$isInternal = () => { return window.config.DOMAIN === Domain.INTERNAL; };
Vue.prototype.$isDev = () => { return process.env.VUE_APP_IS_DEV === 'TRUE'; };

Vue.prototype.$maskedPhone = () => findInputMask('PHONE', getSelectedDomain());
Vue.prototype.$maskedMobilePhone = () => findInputMask('MOBILEPHONE', getSelectedDomain());
Vue.prototype.$maskedPostalCode = () => findInputMask('POSTALCODE', getSelectedDomain());

Vue.prototype.$isPartnerAdmin = () => { return localStorage.user ? JSON.parse(localStorage.user).roleCode === RoleCode.PARTNER_ADMIN : false; };
Vue.prototype.$isPartnerManager = () => { return localStorage.user ? JSON.parse(localStorage.user).roleCode === RoleCode.PARTNER_MANAGER : false; };
Vue.prototype.$isPartnerEmployee = () => { return localStorage.user ? JSON.parse(localStorage.user).roleCode === RoleCode.PARTNER_EMPLOYEE : false; };
Vue.prototype.$isPartnerAudit = () => { return localStorage.user ? JSON.parse(localStorage.user).roleCode === RoleCode.PARTNER_AUDIT : false; };
Vue.prototype.$isSuperAdmin = () => { return localStorage.user ? JSON.parse(localStorage.user).roleCode === RoleCode.BIGBANK_SUPER_ADMIN : false; };
Vue.prototype.$isBigbankAdmin = () => { return localStorage.role === RoleCode.BIGBANK_ADMIN; };
Vue.prototype.$isNetsAdmin = () => { return localStorage.user ? JSON.parse(localStorage.user).roleCode === RoleCode.PARTNER_NETS_ADMIN : false; };
Vue.prototype.$isNetsRegular = () => { return localStorage.user ? JSON.parse(localStorage.user).roleCode === RoleCode.PARTNER_NETS_REGULAR : false; };
Vue.prototype.$isEnabledRegHP = () => Vue.prototype.$isFi() && window.config.FI_REG_HIRE_PURCHASE_ENABLED === 'true';

Vue.prototype.$isSigningProviderWithConsumerLoanAccess = () => {
  return Vue.prototype.$userData().consumerLoanApplicationAccess && Vue.prototype.$userData().providesSigningService;
};

Vue.prototype.$auth = auth({
  router,
});

request.interceptors.request.use(
  config => {
    set(config, 'headers.buildVersion', process.env.VUE_APP_BUILD_VERSION || '-');
    if (localStorage.user) {
      set(config, 'headers.X-BB-User', JSON.parse(localStorage.user).username);
    }

    if (window.config.DOMAIN) {
      set(config, 'headers.X-BB-Organization', window.config.DOMAIN === Domain.INTERNAL
        ? localStorage.getItem('orgCode')
        : DomainToOrganization[window.config.DOMAIN]);
    }

    set(config, 'headers.X-Request-Start', new Date().getTime());

    return config;
  },
);

if (window.config.APM_ENABLED) {
  Vue.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: `pmu-backoffice-${window.config.DOMAIN.toLowerCase()}`,
      serverUrl: window.config.APM_URL,
      environment: window.config.APM_ENVIRONMENT,
      ignoreTransactions: [
        `GET ${window.location.origin}/build`,
        `GET ${window.location.origin}/alive`,
        `GET ${window.location.origin}/api/session/heartbeat`,
      ],
      active: window.config.APM_ACTIVE === 'true',
    },
  });
}

new Vue({
  router,
  i18n,
  render: h => h(App as any),
}).$mount('#app');
