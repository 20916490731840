import Vue from 'vue';
import { Domain, PersonRole } from '@/types/common/enums';
import { CommonApplicationState, defaultSearch } from '@/constants/common/constants';
import { getApplicationColumns } from '@/utils/common/getApplicationColumns';
import { VehicleType } from '@/types/fi/enums';

const stateModel = () => ({
  // partner system common fields
  accruedBonus: null,
  search: defaultSearch(),

  // general application processing fields
  isLoading: false,
  isAprcLoading: false,
  loadingText: null,
  partner: {
    channelId: null,
    channelName: null,
    channelEmail: null,
  },

  // Common AS application fields
  application: CommonApplicationState(),

  // Common product pricing configuration from registry
  productConfiguration: {
    baseAmount: null,
    basePeriod: null,
    contractConclusionFee: null,
    contractConclusionFeeFromProductAmount: null,
    contractConclusionFeeRate: null,
    interestRate: null,
    interestRateFlexible: null,
    maintenanceFee: null,
    maintenanceFeeRate: null,
    maxAmount: null,
    maxContractConclusionFee: null,
    maxGoodsPrice: null,
    maxInterestRate: null,
    maxMonthlyFee: null,
    maxPeriod: null,
    minAmount: null,
    minContractConclusionFee: null,
    minGoodsPrice: null,
    minInterestRate: null,
    minMonthlyPayment: null,
    minPeriod: null,
    product: null,
    productName: null,
    productType: null,
    selfFinancingMinPercent: null,
  },

  ...(
    window.config.DOMAIN !== Domain.INTERNAL
  ) && {
    selectedApplicationColumns: getApplicationColumns({
      showUnfilteredColumns: true,
    }),
  },

  // LV specific processing fields
  ...([
    Domain.LV,
    Domain.INTERNAL,
  ].includes(window.config.DOMAIN) && {
    lv: {
      basFiles: [],
      offerChanged: false,
      newContractNeeded: null,
      isDocumentMathed: null,
      applicantHasGivenSignature: false,
      contractFileUploadType: null,
      contractFiles: [],
      contractChangeUploadFileId: null,
      minimumAmount: null,
    },
  }),

  ...([
    Domain.EE,
    Domain.INTERNAL,
  ].includes(window.config.DOMAIN) && {
    ee: {
      consentFromTaxRegistryNeeded: false,
      offerChanged: false,
      minimumAmount: null,
      maximumAmount: null,
      maximumMonthlyPayment: null,
      downSellAccepted: null,
      downSellActive: false,
      isApprovedAdditionalCheck: false,
    },
  }),

  // FI application and processing fields
  ...([
    Domain.FI,
    Domain.INTERNAL,
  ].includes(window.config.DOMAIN) && {
    fi: {
      offerChanged: false,
      trafi: {
        collateral: {},
        tradeIn: {},
        collateralIsLoading: null,
        collateralRequestFailed: null,
        collateralRequestCompleted: null,
        tradeInIsLoading: null,
        tradeInRequestFailed: null,
        tradeInRequestCompleted: null,
      },
      [VehicleType.COLLATERAL]: {
        regNrChanged: null,
      },
      [VehicleType.TRADE_IN]: {
        regNrChanged: null,
      },
      [PersonRole.CONTACT_PERSON]: {
        personalIdentityCodeChanged: null,
      },
      contactDataChanged: null,
      calculator: {
        hasTradeIn: null,
        hasExtras: null,
        vehicleTotalPrice: null,
        downPaymentTotal: null,
      },
      beneficialOwners: [
        {},
      ],
      representatives: [
        {},
      ],
      signingCombinationsСustomerIds: null,
    },
  }),

  // LT processing fields
  ...([
    Domain.LT,
    Domain.INTERNAL,
  ].includes(window.config.DOMAIN) && {
    lt: {
      offerChanged: false,
      sendUploadLink: false,
      contractNeedsManualReview: null,
      spouseIsPresent: null,
      isDocumentMathed: null,
      applicantHasGivenSignature: false,
      contractFileUploadType: null,
      contractFiles: [],
      contractChangeUploadFileId: null,
      [PersonRole.APPLICANT]: {
        idDocumentAndDataProcessingFiles: [],
        documentFiles: [],
        identificationMethod: null,
        onlineIdentificationDate: null,
      },
      [PersonRole.SPOUSE]: {
        idDocumentAndDataProcessingFiles: [],
        documentFiles: [],
        identificationMethod: null,
        onlineIdentificationDate: null,
      },
      [PersonRole.COBORROWER]: {
        idDocumentAndDataProcessingFiles: [],
        documentFiles: [],
        identificationMethod: null,
        onlineIdentificationDate: null,
      },
    },
  }),
});

const state = Vue.observable(stateModel());

function reset() {
  Object.assign(state, stateModel());
}

export { state, reset, stateModel };

